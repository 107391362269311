* {
  font-family: Calibri, sans-serif;
}

.tableCss {
  text-align: center !important;
  border: 0.5px solid black !important;
  padding: 0px !important;
  background-color: white !important;
  font-size: 13px;
}

.standardUnitTable {
  width: -webkit-fill-available;
  font-size: 13px;
}
.sn {
  width: 1% !important;
}

.thead {
  width: 1% !important;
}

.content {
  font-family: Calibri, sans-serif;
}

.content td {
  /* padding: 5px; */
}
.img {
  height: 100% !important;
  width: 100% !important;
}
.hrStyle {
  margin-top: "4px";
  margin-bottom: "4px";
  padding-top: "0px";
  padding-bottom: "0px";
}

@media screen {
  #printOnly {
    display: none;
  }
  #screenOnly {
    display: block;
  }
}
@media print {
  #printOnly {
    display: block;
  }
  #screenOnly {
    display: none;
  }
}

/* customization to repeat table header */
table {
  /* page-break-inside: avoid !important; */
}

thead {
  display: table-header-group !important;
}

tbody {
  display: table-row-group !important;
}

/* Hide the original table header */
.no-print {
  display: none;
}

.flipedText {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  writing-mode: lr-tb;
  -webkit-text-stroke: thick;
  /* font-size: xxx-large; */
  padding: 0px;
  margin: 0px;
}

.labCertification {
  color: rebeccapurple;
  font-size: medium;
  -webkit-text-stroke-width: medium;
}
.certificateTitle {
  font-size: x-large;
  -webkit-text-stroke-width: medium;
}
.standardUnitTable td{
    text-align: center;
  
}