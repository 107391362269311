.card{
    height:400px;
}
.card .row .icheck-primary {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.signin{
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.signin button{
    min-width: 250px !important;
    margin-top: 10px;
}

.card .row p{
    display: flex;
    justify-content: center;
    align-items: center;
}
body{
}
.content-wrapper{
   height: 800px;

}
.form-horizontal .control-label{
    text-align: left;
}
@media print {
    body{
        background-image: none;
    }
}